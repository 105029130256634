import React, { useEffect, useState } from 'react'
import { Flex, Text, Box, Image } from 'rebass'
import { Link as NavLink } from 'react-scroll'
import { Spin } from 'react-burgers'
import KodeLabsLogoDark from '../svg/kodelabs_logo_dark.svg'
import KodeLabsLogoLight from '../svg/kodelabs_logo_light.svg'
import Fade from 'react-reveal/Fade'
import { useColorMode } from 'theme-ui'
import * as Unicons from '@iconscout/react-unicons'
import { motion } from 'framer-motion'

const Header = () => {
  const linkArray = [
    { name: 'Tech', hoverColor: 'purple' },
    { name: 'Projects', hoverColor: 'green' },
    { name: 'People', hoverColor: 'darkBlue' },
    { name: 'Contact', hoverColor: 'purple' },
  ]

  const [menu, setMenu] = useState(false)
  const [colorMode, setColorMode] = useColorMode()

  useEffect(() => {
    menu
      ? (document.body.style.overflowY = 'hidden')
      : (document.body.style.overflowY = 'auto')
  }, [menu])

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').matches
      ? setColorMode('default')
      : setColorMode('light')
  }, [])

  return (
    <Flex variant="navbar">
      <Box sx={{ zIndex: 3 }}>
        <NavLink
          to="Home"
          smooth={true}
          duration={500}
          style={{ cursor: 'pointer' }}
        >
          <Image
            src={colorMode === 'default' ? KodeLabsLogoDark : KodeLabsLogoLight}
            variant="navLogo"
          />
        </NavLink>
      </Box>
      <Box mx="auto" />
      <Box
        variant="themeToggle"
        sx={{ width: '30px', cursor: 'pointer' }}
        onClick={e => {
          setColorMode(colorMode === 'default' ? 'light' : 'default')
        }}
      >
        {colorMode === 'default' ? (
          <Unicons.UilSun size="30" />
        ) : (
          <Unicons.UilMoon size="30" />
        )}
      </Box>

      <Box sx={{ zIndex: 3, position: 'relative' }}>
        <Spin
          active={menu}
          onClick={() => setMenu(!menu)}
          borderRadius={100}
          lineHeight={3}
          lineSpacing={8}
          color={colorMode === 'default' ? '#FFF' : '#142A4C'}
          sx={{ position: 'fixed' }}
          aria-label="menu-button"
        />
      </Box>

      <Box
        onClick={() => setMenu(false)}
        variant="nav"
        sx={{
          backgroundColor: menu ? 'rgba(4,4,16, 0.9)' : 'rgba(4,4,16, 0)',
          visibility: menu ? 'visible' : 'hidden',
        }}
      >
        <Box>
          {linkArray.map((link, i) => (
            <Fade top opposite when={menu} delay={`${i * 80}`} key={link.name}>
              <NavLink
                to={link.name}
                spy={true}
                smooth={true}
                duration={500}
                offset={-150}
                onClick={() => setMenu(false)}
                title="NavLink"
              >
                <Text
                  variant="navLink"
                  sx={{
                    '&:hover': {
                      color: link.hoverColor,
                      transition: '0.3s ease-in-out',
                    },
                  }}
                >
                  {link.name}
                </Text>
              </NavLink>
            </Fade>
          ))}
        </Box>
      </Box>
    </Flex>
  )
}

export default Header
